<template>
  <section>
    <v-btn elevation="0" class="ml-2" small :to="goToOrdersIndex">
      <v-icon class="grey--text">mdi-arrow-left</v-icon>
      {{ $t("orders") }}
    </v-btn>
    <base-section id="order-details" space="0">
      <base-info-card
        :title="$t('orderDetails')"
        :subtitle="''"
        space="4"
        color="primary"
      >
        <v-card
          color="blue-grey lighten-4"
          class="mx-auto text-start"
          max-width="400"
        >
          <v-list color="blue-grey lighten-4">
            <ItemOrder v-if="order" :order="order" />
          </v-list>

          <v-card-text class="d-flex justify-space-around font-weight-bold">
            <p>{{ $t("orderType") }}: {{ order && $t(order.type) }}</p>
            <p>
              {{ $t("products") }}:
              {{ order && getTotalProductsQty }}
            </p>
          </v-card-text>
        </v-card>
        <ProductsOrder
          v-if="order"
          class="my-3"
          :products="order.products || []"
        />
      </base-info-card>
    </base-section>
  </section>
</template>

<script>
import { getColorStatusOrder, formatPrice } from "@/helpers";
import { orderRoutesName } from "@/router/names";

export default {
  name: "OrderDetailsSection",
  components: {
    ItemOrder: () => import("@/components/orders/ItemOrder.vue"),
    ProductsOrder: () => import("@/components/orders/ProductsOrder.vue"),
  },
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    const { order } = this.$route.params;
    if (!order) this.$router.go(-1);
    this.order = { ...order };
  },
  methods: {
    getColorStatusOrder,
    formatPrice,
  },
  computed: {
    goToOrdersIndex() {
      return { name: orderRoutesName.INDEX.name };
    },
    getTotalProductsQty() {
      if (this.order && this.order.products)
        return this.order.products
          .map(({ qty }) => qty)
          .reduce((a, b) => a + b);
      return 0;
    },
  },
};
</script>

<style lang="sass">
.v-image
  transition: all .3s ease-in-out

.startup-list-card
  &:hover
    h6
      transition: color .3s
      color: var(--v-primary-base)

    .v-image
      transform: scale(1.035)
</style>
